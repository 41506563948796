body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Neon";
  src: local("Neon"),
  url("./fonts/NEON.ttf");
}

.commentsWrapper{
  display: flex;
  flex-direction: column;
  gap:7px;
  border-bottom: 5px;
}
.commentsOuterWrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap:25px;
}
.commentsData{
  border-radius: 5px;
  width: fit-content;
  background-color:rgb(117, 133, 169);
  padding:7px;
  color: white;
  font-size: 18px;
}
.cite{
  position:relative;
  font-size: 12px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notif-wrap{
  display:flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.logNotif{
  width: fit-content;
  height: fit-content;
}

#header{
  display:flex;
  justify-content:center;
  margin-top: 3vw;
}
#logo{
  width:40vw;
}

#loginForm{
  margin-top: 2vw;
  display:flex;
  justify-content:center;
  text-align: center;
}

.form-group{
  padding:2px;
}

.input-box{
  border: none;
  font-size:medium;
  width: 240px;
}

.input-box:focus-visible{
  outline:none;
  margin-right:2px;
}

.input-wrapper{
  display:flex;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  padding: 5px;
  width:285px;
  align-items: center;
  margin:1vw;
  transition: all .3s;
}

.input-wrapper:hover{
  box-shadow: 0 1px 4px 0px rgb(32 100 32 / 60%);
  background-color: rgb(32 100 32 / 3%)
}

.input-wrapper:focus-within{
  box-shadow: 0 1px 4px 0px rgb(32 100 32/ 60%)
}
.timewrap{
  display: flex;
}

.addr-sel{
  border:0px;
  outline: 0px;
  background-color: white;
  padding-left: 3px;
  padding-right: 3px;
}
.input-wrapper2{
  display:flex;
  flex-wrap: wrap;
  border-radius: 0px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  padding: 5px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  margin:1vw;
  transition: all .3s;
  margin-bottom: 14px;
}

.input-wrapper2:hover{
  box-shadow: 0 1px 4px 0px rgb(32 100 32 / 60%);
  background-color: rgb(32 100 32 / 3%)
}

.input-wrapper2:focus-within{
  box-shadow: 0 1px 4px 0px rgb(32 100 32/ 60%)
}

.input-box2{
  border: none;
  font-size:small;
  width: fit-content;
  padding-left:3px;
  padding-right:3px;
}

.input-box3{
  border:none;
  font-size: small;
  width:89px;
  padding-left:3px;
  padding-right:3px;
}
.input-box3:focus-visible{
  outline:none;
}

.input-box2:focus-visible{
  outline:none;
}

.input-icon{
  padding-left: 5px;
  width: 20px;
  height: 20px;
  padding-right: 8px;
}

#login-button{
  margin-top: 3vw;
  font-size: larger;
  color: black;
  transition: all .6s;
}

#login-button:hover{
  letter-spacing: 7px;
  font-size: 16px;
  background-color: none;
}

#cnclLgnBtn{
  margin-top: 3vw;
  font-size: larger;
  color: black;
  transition: all .6s;
}

#cnclLgnBtn:hover{
  letter-spacing: 7px;
  font-size: 16px;
  background-color: none;
}

.gatheringContent{
  position: relative;
  top:75px;
  width: 1350px;
  width:90vw;
  margin:auto;
  white-space: pre-wrap;
  font-size: 20px;
  border-style:solid;
  border-radius: 10px;
  border-color: grey;
}

.gatheringTitle{
  font-family:'Neon';
  font-weight: bold;
  display: flex;
  margin: auto;
  margin-top:35px;
  margin-top: 4.5vw;
  justify-content: center;
  align-content: bottom;
  font-size:36px;
}

#rsvp-btn{
  font-size: 18px;
}

#rsvp{
  display: flex;
  align-items: left;
  flex-wrap:wrap;
  transition: all .5s;
  margin-bottom: 20px;
}

#rsvp-content{
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

#notification{
  border-style: solid;
  border-color: rgb(184,218,184);
  border-radius: 5px;
  width:fit-content;
  padding: 5px;
  font: bold;
}
#notification:empty{
  border: none;
  height: 29px;
}

.gatheringParagraph{
  padding-left: 2vw;
  padding-right: 7px;
}

.nav-top{
  background-color: rgb(184,218,184);
  display: flex;
  flex-direction: row;
  width: 94vw;
  padding-left: 3vw;
  padding-right: 3vw;
  margin: auto;
  margin-bottom:10px;
  justify-content: center;
  gap:10px;
  top:0;
  position: fixed;
  z-index: 100;
}
.input-wrapper3{
    border-radius: 0px;
    box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
    padding: 3px;
    width: fit-content;
    height: fit-content;
    align-items: center;
}
.nav-right{
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

#welcome{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 6px;
  font-size:normal;
}

#nav-left{
  display: flex;
  justify-content: left;
}

#editBtn{
  font-size: 20px;
}

#addGuestBtn{
  font-size: 16px;
  margin-top: 7px;
}

.postInfo{
  display: flex;
  margin: auto;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.para{
  margin: 10px;
}

#addGuest{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
  margin-left: 7px;
  margin-bottom: 10px;
}
#addCommentBtn{
  font-size: 16px;
  margin-top: 7px;
}
#addComment{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
  gap: 10px;
  margin-left: 7px;
  margin-bottom: 10px;
  height: fit-content;
}
#comment-input-wrapper{
  display: flex;
  flex-grow:.5;
  border-radius: 7px;
}
.spacer{
  height: 10px;
}
#comment-input-box{
  flex-grow: 1;
  padding:5px;
  font-size: 14px;
}
.newGath{
  position: relative;
  top: 60px;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}
.newGathInputs{
  display: flex;
  flex-wrap: wrap;
}

.newGatheringTitle{
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  text-align: center;
  width: fit-content;
}
.newGathWrapper{
  border-style: solid;
  border-color: grey;
  border-radius: 5px;
  padding:15px;
  width: 1350px;
  width:90vw;
  margin: auto;
}

.rsvpRadio{
  position:absolute;
  width: 0;
  opacity: 0;
  pointer-events: none;
}

#thumbIconUp{
  padding: 7px;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  color: rgb(90, 89, 89);
  background-color: white;
  width: 28px;
  height: 28px;
  margin: 7px;
  margin-right:3px;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

#thumbIconUp[check='true']{
  color:white;
  background-color: rgb(61, 140, 61);  border-color: white;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
#thumbIconUp:hover{
  box-shadow: 0 1px 4px 0px rgb(32 100 32 / 60%);
  transition: all .25s
}

#thumbIconDown{
  padding: 7px;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  color: rgb(90, 89, 89);
  background-color: white;
  width: 28px;
  height: 28px;
  margin: 7px;
  margin-right:3px;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

#thumbIconDown[check='true']{
  color:white;
  background-color: rgb(220, 80, 80);  border-color: white;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
#thumbIconDown:hover{
  box-shadow: 0 1px 4px 0px rgb(32 100 32 / 60%);
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

#thumbIconNeutral{
  padding: 7px;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  color: rgb(90, 89, 89);
  background-color: white;
  width: 28px;
  height: 28px;
  margin: 7px;
  margin-right:3px;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

#question{
  font-size: x-large;
  width:fit-content;
  margin:auto;
  font-weight: bolder;
}

#thumbIconNeutral[check='true']{
  color:white;
  background-color:rgb(102, 102, 255);  
  border-color: white;
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}
#thumbIconNeutral:hover{
  box-shadow: 0 1px 4px 0px rgb(32 100 32 / 60%);
  -webkit-transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -o-transition: all .25s ease-in;
  -ms-transition: all .25s ease-in;
  transition: all .25s ease-in;
}

.thumb{
  width: 10px;
  color: white;
  border-radius:20px;
  padding: 5px;
}
.thumbIcon[check='true']:hover{
  color:white;
}

.thumbslabel{
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.rsvpContentWrapperUp{
  display:flex;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  width: fit-content;
  background-color: rgb(61, 140, 61);
  margin-top: 4px;
  padding:3px;
  color: white;
  margin:auto;
  margin-top: 7px;
  margin-bottom:5px;
}
.rsvpContentWrapperDown{
  display:flex;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  width: fit-content;
  background-color: rgb(220, 80, 80);
  margin-top: 4px;
  padding:3px;
  color: white;
  margin:auto;
  margin-top: 7px;
  margin-bottom:5px;
}
.rsvpContentWrapperNeutral{
  display:flex;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 32%);
  width: fit-content;
  background-color:rgb(102, 102, 255);
  margin-top: 4px;
  padding:3px;
  color: white;
  margin:auto;
  margin-top: 7px;
  margin-bottom:5px;
}

.rsvpContentText{
  margin:auto;
  padding-left:10px;
  margin-right: 7px;
  font-size: 16px;
}

.rsvpMain{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 14px;
}

.rsvpHead{
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
}
.rsvpBox{
  width: 115px;
  height:fit-content;
  border-style: solid;
  border-color: grey;
  background-color:rgb(255, 255, 255);
  border-radius: 10px;
  padding:7px;
  margin-bottom: 7px;
}
.rsvpWrapper{
  display: flex;
  flex-direction: column;
}
.rsvpFamilyName{
  font-weight:bold;
  width: fit-content;
  font-size: 20px;
  margin: auto;
  padding-top:3px;
  padding-bottom: 3px;
}

#archTitle{
  margin-top: 3px;
}

#arch-btn{
  font-size: large;
}

#btnId{
  font-size: large;
}
#hostbtn{
  font-size:large;
}

#archbtn{
  font-size: large;
}

#homebtn{
  font-size: large;
}

#editBtn{
  font-size: larger;
}

#rsvp-name{
  padding: 7px;
  margin-top: auto;
  margin-bottom: auto;
}

#thumbup{
  width: 16px;
  padding-right:7px;
}

#thumbdown{
  position: relative;
  top: 2px;
  width: 16px;
  padding-right:7px;
}

#thumbneutral{
  font-size:24px;
  font:bolder;
  padding-right:5px;
  padding-top: .5px;
  padding-bottom: .5px;
}

.guests{
  font-size: 20px;
  width:fit-content;
  margin:auto;
  margin-top:10px;
}

.guestnames{
  font-size:17px;
  width: fit-content;
  margin: auto;
}

#location{
  margin-bottom: 17px;
}
#locationdata{
  margin-top: 10px;
}
#editicon{
  color:rgb(61, 140, 61);
  border-radius: 20px;
  padding: 3px;
  transition: all .3s
}
#editicon:hover{
  background-color: rgb(61, 140, 61);
  color:white;
}
.editicon{
  position: relative;
  top:-10px;
  left:-10px;
}

.hcgath{
  padding: 10px;
  border-radius: 10px;
  align-content: center;
  justify-content: center;
  color:black;
}
#hcgath2{
  padding: 10px;
  border-radius: 10px;
  align-content: center;
  justify-content: center;
  color:black;
}

#post-btn{
  display: flex;
  font-size: large;
  margin-top: 20px;
  margin-left: 20px;
}
#schbtn{
  font-size: large;
  width: fit-content;
}
.gatheringParagraph2{
  margin-left:22px;
}
#schbtnwrap{
  margin-top: 30px;
  margin-bottom: -10px;
}
.docs{
  position: relative;
  top: 95px;
  width: 1750px;
  height: 780px;
  width:90vw;
  height:40vw;
}
.schedule-header{
    position: relative;
    top: 80px;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: fit-content;
    font-family: 'Neon';
    font-size: xx-large;
}
.docsWrapper{
  display: flex;
  width: fit-content;
  margin: auto;
}
#mobile{
  display: none;
}
@media (max-width:700px){
  .editicon{
    left:-2em;
    top:.25em;
  }
  #editicon{
    background-color: rgb(61, 140, 61);
    color:white;
    border-radius:10px;
  }
  #post-btn{
    margin:auto;
    margin-top:20px;
    margin-bottom:20px;
  }
  .hcgath2{
    font-size: 28px;
  }
  .gatheringParagraph2{
    display:flex;
    margin:auto;
    margin-top:20px;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
  }
}

@media(max-width:500px){
  #desktop{
    display: none;
  }
  #mobile{
    display:flex;
  }
  .docs{
    height:420px;
  }
  #menu{
    font-size: large;
  }
  #menu-btns-wrapper{
    width: 175px;
    box-shadow: 0px 1px 4px 0 rgb(32 30 32 / 60%);
    margin-left: auto;
    margin-right: auto;
    left:0;
    right: 0;
    position: absolute;
    background-color: white;
    display:flex;
    top: 50px;
    flex-direction: column;
    z-index: 100;
  }
  #logo{
    margin-top:5em;
    margin-bottom: 5em;
    width: 17em;
  }
  #rsvp-name{
    width: fit-content;
    margin-top:auto;
    margin-bottom: auto;
    margin-right:0;
    margin-left:0;
    padding:0;
  }
  .hcgath2{
    text-align: center;

  }
  .editicon{
    left:-2em;
    top:.25em;
  }
}